var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"还款情况"}},[_c('el-select',{attrs:{"placeholder":"选择状态","clearable":""},model:{value:(_vm.query.status),callback:function ($$v) {_vm.$set(_vm.query, "status", $$v)},expression:"query.status"}},[_c('el-option',{attrs:{"label":"还款中","value":0}}),_c('el-option',{attrs:{"label":"还款完成","value":1}})],1)],1),_c('el-form-item',{attrs:{"label":"教练"}},[_c('coachSelect',{attrs:{"coachid":_vm.coach_id},on:{"update:coachid":function($event){_vm.coach_id=$event}}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("搜索")])],1)],1),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('link-to',{attrs:{"to":{ name: 'coachDetails', query: { id: row.coach_id } }}},[_vm._v(" "+_vm._s(row.map.coach.real_name))])],1)]}},{key:"ratio",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v("扣款比例："+_vm._s((row.ratio * 100).toFixed(0))+"%")]),_c('div',[_vm._v(" 课程扣款金额："+_vm._s(row.fixed_repayment == 0 ? "未设置" : row.fixed_repayment + "元")+" ")])]}},{key:"amount",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v("欠款："+_vm._s(row.amount)+"元")]),_c('div',{staticStyle:{"color":"#f78d36"}},[_vm._v("待还："+_vm._s(row.surplus)+"元")])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.typeMap[row.type])+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === 0)?_c('el-tag',{attrs:{"size":"small","type":"info"}},[_vm._v("待审核")]):_vm._e(),(row.status === 1)?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v("通过")]):_vm._e(),(row.status === 2)?_c('el-tag',{attrs:{"size":"small","type":"danger"}},[_vm._v("拒绝")]):_vm._e()]}},{key:"handle",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.openUpdate(row)}}},[_vm._v("修改")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.openCashList(row)}}},[_vm._v("还款详情")])]}}])}),_c('el-dialog',{attrs:{"title":"修改扣款比例","visible":_vm.show,"width":"30%"},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',[_c('el-form',[_c('el-form-item',{attrs:{"label":"扣款比例"}},[_c('el-input',{attrs:{"type":"Number","placeholder":"请扣款比例"},model:{value:(_vm.deduct_ratio),callback:function ($$v) {_vm.deduct_ratio=$$v},expression:"deduct_ratio"}}),_vm._v("% ")],1),_c('el-form-item',{attrs:{"label":"课程扣款金额"}},[_c('el-input',{attrs:{"type":"Number","placeholder":"请输入内容"},model:{value:(_vm.fixed_repayment),callback:function ($$v) {_vm.fixed_repayment=$$v},expression:"fixed_repayment"}}),_vm._v("元 ")],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.updateRatio}},[_vm._v("修 改")])],1)]),_c('el-dialog',{attrs:{"title":"还款记录","visible":_vm.repayShow,"width":"30%"},on:{"update:visible":function($event){_vm.repayShow=$event}}},[_c('auto-table',{attrs:{"DataList":_vm.cDataList,"option":_vm.cOption,"total":_vm.cTotal},on:{"changePage":_vm.getCashList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }