<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="还款情况">
        <el-select v-model="query.status" placeholder="选择状态" clearable>
          <el-option label="还款中" :value="0"></el-option>
          <el-option label="还款完成" :value="1"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="教练">
        <coachSelect :coachid.sync="coach_id"></coachSelect>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #name="{ row }">
        <div>
          <link-to :to="{ name: 'coachDetails', query: { id: row.coach_id } }">
            {{ row.map.coach.real_name }}</link-to
          >
        </div>
      </template>
      <template #ratio="{ row }">
        <div>扣款比例：{{ (row.ratio * 100).toFixed(0) }}%</div>
        <div>
          课程扣款金额：{{
            row.fixed_repayment == 0 ? "未设置" : row.fixed_repayment + "元"
          }}
        </div>
      </template>
      <template #amount="{ row }">
        <div>欠款：{{ row.amount }}元</div>
        <div style="color: #f78d36">待还：{{ row.surplus }}元</div>
      </template>
      <template #type="{ row }">
        {{typeMap[row.type]}}
      </template>
      <template #status="{ row }">
        <el-tag size="small" type="info" v-if="row.status === 0">待审核</el-tag>
        <el-tag size="small" type="success" v-if="row.status === 1"
          >通过</el-tag
        >
        <el-tag size="small" type="danger" v-if="row.status === 2">拒绝</el-tag>
      </template>
      <template #handle="{ row }">
        <el-button size="small" type="success" @click="openUpdate(row)"
          >修改</el-button
        >
        <el-button size="small" @click="openCashList(row)" type="primary"
          >还款详情</el-button
        >
      </template>
    </auto-table>

    <!-- 修改 -->
    <el-dialog title="修改扣款比例" :visible.sync="show" width="30%">
      <div>
        <el-form>
          <el-form-item label="扣款比例">
            <el-input
              type="Number"
              v-model="deduct_ratio"
              placeholder="请扣款比例"
            ></el-input
            >%
          </el-form-item>
          <el-form-item label="课程扣款金额">
            <el-input
              type="Number"
              v-model="fixed_repayment"
              placeholder="请输入内容"
            ></el-input
            >元
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="updateRatio">修 改</el-button>
      </span>
    </el-dialog>

    <el-dialog title="还款记录" :visible.sync="repayShow" width="30%">
       <auto-table
      :DataList="cDataList"
      :option="cOption"
      :total="cTotal"
      @changePage="getCashList"
    >
       </auto-table>
    </el-dialog>
  </div>
</template>

<script>
import coachSelect from "@/components/select/coachSelect.vue";

export default {
  components: {
    coachSelect,
  },
  data() {
    return {
        repayShow:false,
      DataList: [],
      coach_id: "",
      query: {
        status: '',
        real_name: "",
        createTime: [],
        type: "",
      },
      Option: [
        { name: "教练", value: "name", type: "custom" },
        { name: "还款比例", value: "ratio", type: "custom" },
        { name: "欠款金额", value: "amount", type: "custom" },
        { name: "开通类型", value: "type", type: "custom" },
          { name: "说明", value: "remark" },
        { name: "提交时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
      curAuth: {},
      show: false,
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
      deduct_ratio: 0,
      fixed_repayment: 0,

       cOption: [
         { name: "说明", value: "remark" },
          { name: "还款金额", value: "amount" },
        { name: "扣款时间", value: "create_time" },
       
      ],
      cDataList:[],
      cTotal: 0,
      typeMap:{
        create_union:'创建公会',
        coach_sign:'特约教练',
        coach_dev:'开发人员',

      }
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    getLabel(list, v) {
      for (let index = 0; index < this[list].length; index++) {
        if (this[list][index].value === v) {
          return this[list][index].label;
        }
      }
    },
    openCashList(row){
          this.curAuth = row;
          this.repayShow = true
        this.getCashList(1)
    },
    getCashList(v) {
      let page = v || 1;
      let params = {
        currentPage: page,
        pageSize: 10,
        arrears_id: this.curAuth.id,
      };
    
      this.$axios({
        url: "/user/coachCashRecord/queryManagerListPage",
        params,
      }).then((res) => {
        this.cDataList = res.data.data.rows;
        this.cTotal = res.data.data.total;
      });
    },

    openUpdate(row) {
      this.curAuth = row;
      this.deduct_ratio = (row.ratio * 100).toFixed(0);
      this.fixed_repayment = row.fixed_repayment;
      this.show = true;
    },
    updateRatio() {
      if (!this.deduct_ratio > 100 || this.deduct_ratio <= 10) {
        this.$message("还款比例最低10%");
        return;
      }
      let data = {
        id: this.curAuth.id,
        ratio: this.deduct_ratio / 100,
        fixed_repayment: this.fixed_repayment,
      };
      this.$axios({
        url: "/user/coachArrears/update",
        data,
        method: "post",
      }).then((res) => {
        this.getList(1);
        this.$message("修改成功");
        this.show = false;
      });
    },
    //列表
    getList(v) {
      let page = v || 1;
      let params = {
        currentPage: page,
        pageSize: 10,
        status: this.query.status,
        coach_id: this.coach_id || null,
      };
      if (this.query.createTime !== null && this.query.createTime.length > 0) {
        params.start_time = this.query.createTime[0] + " 00:00:00";
        params.end_time = this.query.createTime[1] + " 23:59:59";
      }

      this.$axios({
        url: "/user/coachArrears/queryManagerListPage",
        params,
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>